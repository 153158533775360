<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <!-- START:: PAGE CONTENT -->
        <div class="about_us_page_wrapper fadeIn" v-else>
            <!-- START:: BREADCRUMB -->
            <Breadcrumb>
                <template v-slot:page_title>
                    {{ $t("TITLES.about_us") }}
                </template>
                <template v-slot:breadcrumb_current_page>
                    {{ $t("TITLES.about_us") }}
                </template>
            </Breadcrumb>
            <!-- END:: BREADCRUMB -->

            <!-- START:: ABOUT US PAGE CONTENT -->
            <AboutUs :aboutUsData="homeData?.about" />
            <!-- END:: ABOUT US PAGE CONTENT -->

            <!-- START:: CONTACT SECTION -->
            <ContactUs :contactUsData="homeData?.contacts" />
            <!-- END:: CONTACT SECTION -->
        </div>
        <!-- END:: PAGE CONTENT -->
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS

import AboutUs from "../../components/general/AboutUs.vue";
import ContactUs from "../../components/general/ContactUs.vue";

export default {
    name: "AboutUsPage",

    components: {
        MainLoader,
        Breadcrumb,
        AboutUs,
        ContactUs,
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: HOME DATA
            homeData: null,
            // END:: HOME DATA
        };
    },

    methods: {
        // START:: GET HOME PAGE DATA
        getHomeData() {
            // START:: GET TEACHER HOME DATA
            this.isLoading = true;
            var userType = localStorage.getItem("elmo3lm_elmosa3d_user_type");
            if (userType == "teacher") {
                this.$axios
                    .get("teacher/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                        this.homeData = res.data.data;
                    });
            } else if (userType == "student") {
                this.$axios
                    .get("student/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                        this.homeData = res.data.data;
                    });
            } else if (userType == "parent") {
                this.$axios
                    .get("parent/home", {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                        this.homeData = res.data.data;
                    });
            } else {
                this.$axios
                    .get("visitor/home", {
                        headers: {
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                        this.homeData = res.data.data;
                    });
            }
            // END:: GET TEACHER HOME DATA
        },
        // END:: GET HOME PAGE DATA
    },

    mounted() {
        // START:: FIRE METHODS
        this.getHomeData();
        // END:: FIRE METHODS
    },
};
</script>
