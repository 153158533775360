<template>
    <div>
        <!-- START:: CONTENT -->
        <div class="strengthening_requests_wrapper">
            <!-- START:: BREADCRUMB -->
            <Breadcrumb>
                <template v-slot:page_title>
                    {{ $t("TITLES.strengthening_requests") }}
                </template>
                <template v-slot:breadcrumb_current_page>
                    {{ $t("TITLES.strengthening_requests") }}
                </template>
            </Breadcrumb>
            <!-- END:: BREADCRUMB -->

            <!-- START:: EMPTY MESSAGE -->
            <EmptyRequestsMessage
                v-if="
                    strengtheningRequestsData?.strengtheningRequestsData
                        ?.length == 0
                "
            />
            <!-- END:: EMPTY MESSAGE -->

            <div class="strengthening_requests_cards_wrapper" v-else>
                <div class="container">
                    <div class="row">
                        <!-- START:: STRENGTHENING REQUEST CARD -->

                        <div
                            v-for="request in strengtheningRequestsData?.strengtheningRequestsData"
                            :key="request.id"
                            class="col-lg-6 my-3"
                        >
                            <!-- START:: STRENGTHENING REQUEST SKELETON LOADER -->
                            <MainLoader v-if="isLoading" />
                            <!-- END:: STRENGTHENING REQUEST SKELETON LOADER -->

                            <!-- START:: STRENGTHENING REQUEST CARD -->
                            <RequestCard
                                :requestData="request"
                                class="fadeIn"
                                v-else
                            />
                            <!-- END:: STRENGTHENING REQUEST CARD -->
                        </div>
                        <!-- END:: STRENGTHENING REQUEST CARD -->
                        <!-- START:: PAGENATION FOR TEACHER-->
                        <Pagenation
                            :last_page="strengtheningRequestsData.last_page"
                            :current_page="current_page"
                            @pagenationClick="pagenationClick"
                        ></Pagenation>
                        <!-- END:: PAGENATION FOR TEACHER-->
                    </div>
                </div>
            </div>
        </div>
        <!-- END:: CONTENT -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING BREADCRUMB
import Breadcrumb from "../components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

// START:: IMPORTING EMPTY MESSAGES
import EmptyRequestsMessage from "../components/ui/emptyMessages/EmptyRequestsMessage.vue";
// END:: IMPORTING EMPTY MESSAGES

// START:: IMPORTING LOADERS
import MainLoader from "../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS

// START:: IMPORTING REQUEST CARD
import RequestCard from "../components/strengtheningRequest/StrengtheningRequestCard.vue";
// END:: IMPORTING REQUEST CARD
// START:: PAGENATION
import Pagenation from "../components/ui/pagenation";
// END:: PAGENATION
export default {
    name: "StrengtheningRequests",

    components: {
        Breadcrumb,
        EmptyRequestsMessage,
        MainLoader,
        RequestCard,
        Pagenation,
    },

    computed: {
        // START:: VUEX STRENGTHENING REQUESTS GETTER
        ...mapGetters("StrengtheningRequestsModule", [
            "strengtheningRequestsData",
        ]),
        // END:: VUEX STRENGTHENING REQUESTS GETTER
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: true,
            // END:: HANDLING SKELETON LOADER APPERANCE

            strengtheningRequests: [],
            // START:: PAGENATION
            current_page: 1,
            // END:: PAGENATION
        };
    },

    methods: {
        //    START:: VUEX GET STRENGTHENING REQUESTS DATA
        ...mapActions("StrengtheningRequestsModule", [
            "getStrengtheningRequests",
        ]),
        //    END:: VUEX GET STRENGTHENING REQUESTS DATA
        // START:: PAGENATION
        pagenationClick(pageNum) {
            this.current_page = pageNum;
            this.getStrengtheningRequests(this.current_page);
        },
        // END:: PAGENATION
    },

    mounted() {
        // START:: FIRE METHODS
        this.getStrengtheningRequests(this.current_page);
        // END:: FIRE METHODS

        setTimeout(() => {
            this.isLoading = false;
        }, 1500);
    },
};
</script>
