<template>
    <div class="courses_cats fadeIn">
        <!-- START:: IMAGE HEADER -->
        <ImageHeader>
            <template>
                <div class="header_container">
                    <div class="container">
                        <div class="header_title_wrapper">
                            <h1>
                                {{ courseDetails?.subject_name?.subject_name }}
                            </h1>
                        </div>
                    </div>
                </div>
            </template>
        </ImageHeader>
        <!-- END:: IMAGE HEADER -->
        <div class="courses_cats_content_wrapper">
            <div class="container">
                <!-- START:: TABS -->
                <Tabs :tabsContent="tabsContent" @getItems="getItems">
                    <!-- START:: REGISTERED COURSES TAB -->
                    <template #registered_courses>
                        <!-- START:: MAIN LOADER -->
                        <MainLoader
                            class="custom_loader"
                            v-if="
                                isLoading &&
                                currentTab == 'registered_courses' &&
                                current_page == 1
                            "
                        />
                        <!-- END:: MAIN LOADER -->
                        <div class="fadeIn" v-else>
                            <!-- START:: EMPTY COURSES MESSAGE -->
                            <EmptyCoursesMessage
                                v-if="registeredCourses.length == 0"
                            />
                            <!-- END:: EMPTY COURSES MESSAGE -->
                            <div class="registered_courses_wrapper" v-else>
                                <div class="row">
                                    <!-- START:: REGISTERED COURSE CARD -->
                                    <div
                                        v-for="course in registeredCourses"
                                        :key="course.id"
                                        class="col-lg-3 my-3 fadeIn"
                                    >
                                        <!-- START:: COURSE CARD -->
                                        <router-link
                                            :to="
                                                registeredUserType == 'student'
                                                    ? `/student/course-details/record/${course.id}`
                                                    : `/parent/course-details/record/${course.id}`
                                            "
                                        >
                                            <ImagedCard>
                                                <template v-slot:card_image>
                                                    <img
                                                        :src="course.image"
                                                        alt="Course Category Image"
                                                    />
                                                    <div class="overlay">
                                                        <div
                                                            class="
                                                                instructor_info_wrapper
                                                            "
                                                        >
                                                            <img
                                                                :src="
                                                                    course
                                                                        .teacher
                                                                        .image
                                                                "
                                                                alt="Instructor Image"
                                                                width="100"
                                                                height="100"
                                                            />

                                                            <div class="text">
                                                                <p class="name">
                                                                    {{
                                                                        course
                                                                            .teacher
                                                                            .fullname
                                                                    }}
                                                                </p>
                                                                <p class="rate">
                                                                    <span
                                                                        ><i
                                                                            class="
                                                                                fas
                                                                                fa-star
                                                                            "
                                                                        ></i
                                                                    ></span>
                                                                    <span>
                                                                        {{
                                                                            course.avg_rate
                                                                        }}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-slot:card_text>
                                                    <div class="wrapper">
                                                        <p class="course_name">
                                                            {{
                                                                course
                                                                    .subject_name
                                                                    .subject_name
                                                            }}
                                                        </p>
                                                        <p class="duration">
                                                            <span
                                                                >{{
                                                                    course.num_of_hour
                                                                }}
                                                                ساعات
                                                            </span>
                                                        </p>
                                                    </div>
                                                </template>
                                            </ImagedCard>
                                        </router-link>
                                        <!-- END:: COURSE CARD -->
                                    </div>
                                    <!-- END:: REGISTERED COURSE CARD -->
                                </div>
                            </div>
                            <!-- START:: PAGENATION FOR TEACHER-->
                            <Pagenation
                                :last_page="last_page"
                                :current_page="current_page"
                                @pagenationClick="pagenationClick"
                            ></Pagenation>
                            <!-- END:: PAGENATION FOR TEACHER-->
                        </div>
                    </template>
                    <!-- END:: REGISTERED COURSES TAB -->

                    <!-- START:: ONLINE COURSES TAB -->
                    <template
                        #online_courses
                        v-if="currentTab == 'online_courses'"
                    >
                        <!-- START:: MAIN LOADER -->
                        <MainLoader
                            class="custom_loader"
                            v-if="
                                isLoading &&
                                currentTab == 'online_courses' &&
                                current_page == 1
                            "
                        />
                        <!-- END:: MAIN LOADER -->
                        <div class="fadeIn" v-else>
                            <!-- START:: EMPTY COURSES MESSAGE -->
                            <EmptyCoursesMessage
                                v-if="onlineCourses.length == 0"
                            />
                            <!-- END:: EMPTY COURSES MESSAGE -->
                            <div class="registered_courses_wrapper" v-else>
                                <div class="row">
                                    <!-- START:: ONLINE COURSE CARD -->
                                    <div
                                        v-for="course in onlineCourses"
                                        :key="course.id"
                                        class="col-lg-3 my-3 fadeIn"
                                    >
                                        <!-- START:: COURSE CARD -->
                                        <router-link
                                            :to="
                                                registeredUserType == 'student'
                                                    ? `/student/course-details/online/${course.id}`
                                                    : `/parent/course-details/online/${course.id}`
                                            "
                                        >
                                            <ImagedCard>
                                                <template v-slot:card_image>
                                                    <img
                                                        :src="course.image"
                                                        alt="Course Category Image"
                                                    />
                                                    <div class="overlay">
                                                        <div
                                                            class="
                                                                instructor_info_wrapper
                                                            "
                                                        >
                                                            <img
                                                                :src="
                                                                    course
                                                                        .teacher
                                                                        .image
                                                                "
                                                                alt="Instructor Image"
                                                                width="100"
                                                                height="100"
                                                            />

                                                            <div class="text">
                                                                <p class="name">
                                                                    {{
                                                                        course
                                                                            .teacher
                                                                            .fullname
                                                                    }}
                                                                </p>
                                                                <p class="rate">
                                                                    <span
                                                                        ><i
                                                                            class="
                                                                                fas
                                                                                fa-star
                                                                            "
                                                                        ></i
                                                                    ></span>
                                                                    <span>
                                                                        {{
                                                                            course.avg_rate
                                                                        }}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-slot:card_text>
                                                    <div class="wrapper">
                                                        <p class="course_name">
                                                            {{
                                                                course
                                                                    .subject_name
                                                                    .subject_name
                                                            }}
                                                        </p>
                                                        <p class="start_date">
                                                            <span class="text"
                                                                >{{
                                                                    $t(
                                                                        "TITLES.start_date"
                                                                    )
                                                                }}
                                                            </span>
                                                            <span class="date"
                                                                >{{
                                                                    course.start_date
                                                                }}
                                                            </span>
                                                        </p>
                                                    </div>

                                                    <div class="wrapper">
                                                        <p class="price">
                                                            {{ course.price }}
                                                            {{
                                                                course.currency
                                                            }}
                                                        </p>
                                                    </div>
                                                </template>
                                            </ImagedCard>
                                        </router-link>
                                        <!-- END:: COURSE CARD -->
                                    </div>
                                    <!-- END:: ONLINE COURSE CARD -->
                                </div>
                            </div>
                            <!-- START:: PAGENATION FOR TEACHER-->
                            <Pagenation
                                :last_page="last_page"
                                :current_page="current_page"
                                @pagenationClick="pagenationClick"
                            ></Pagenation>
                            <!-- END:: PAGENATION FOR TEACHER-->
                        </div>
                    </template>
                    <!-- END:: ONLINE COURSES TAB -->
                </Tabs>
                <!-- END:: TABS -->
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING IMAGE HEADER
import ImageHeader from "../../components/ui/ImageHeader.vue";
// END:: IMPORTING IMAGE HEADER

// START:: IMPORTING TABS
import Tabs from "../../components/structure/Tabs.vue";
// END:: IMPORTING TABS

// START:: IMPORTING UI IMAGED CARD
import ImagedCard from "../../components/ui/ImagedCard.vue";
// END:: IMPORTING UI IMAGED CARD
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
// START:: IMPORTING EMPTY MESSAGES
import EmptyCoursesMessage from "../../components/ui/emptyMessages/EmptyCoursesMessage.vue";
// END:: IMPORTING EMPTY MESSAGES
// START:: PAGENATION
import Pagenation from "../../components/ui/pagenation";
// END:: PAGENATION
export default {
    name: "CoursesCategories",

    components: {
        ImageHeader,
        MainLoader,
        Tabs,
        ImagedCard,
        EmptyCoursesMessage,
        Pagenation,
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: HANDLING SKELETON LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING SKELETON LOADER APPERANCE

            // START:: TABS CONTENT
            tabsContent: [
                {
                    tab: this.$t("TABS.registered_courses"),
                    key_name: "registered_courses",
                },
                {
                    tab: this.$t("TABS.online_courses"),
                    key_name: "online_courses",
                },
            ],
            // END:: TABS CONTENT
            // START:: COURSE DETAILS DATA
            courseDetails: null,
            registeredCourses: null,
            onlineCourses: null,
            // END:: COURSE DETAILS DATA
            registeredUserType: localStorage.getItem(
                "elmo3lm_elmosa3d_user_type"
            ),
            // START:: PAGENATION
            currentTab: "registered_courses",
            last_page: null,
            current_page: 1,
            // END:: PAGENATION
        };
    },
    methods: {
        getItems(item) {
            this.currentTab = item;
            this.getCourseDetails();
        },
        // START:: GET COURSE DATA
        getCourseDetails() {
            this.isLoading = true;
            if (this.registeredUserType == "student") {
                if (this.currentTab == "registered_courses") {
                    this.$axios
                        .get(
                            `student/courses/${this.$route.params.id}?page=${this.current_page}&subject_kind=record`,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        localStorage.getItem(
                                            "elmo3lm_elmosa3d_user_token"
                                        ),
                                    "Accept-language": localStorage.getItem(
                                        "elmo3lm_elmosa3d_app_lang"
                                    ),
                                    "cache-control": "no-cache",
                                    Accept: "application/json",
                                },
                            }
                        )
                        .then((res) => {
                            this.courseDetails = res.data;
                            this.last_page = res.data.meta.last_page;
                            this.registeredCourses = res.data.data;
                            this.isLoading = false;
                        });
                    // ============ END:: RECORD CORSES
                } else if (this.currentTab == "online_courses") {
                    // ============ START:: ONLINE CORSES
                    this.$axios
                        .get(
                            `student/courses/${this.$route.params.id}?page=${this.current_page}&subject_kind=online`,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        localStorage.getItem(
                                            "elmo3lm_elmosa3d_user_token"
                                        ),
                                    "Accept-language": localStorage.getItem(
                                        "elmo3lm_elmosa3d_app_lang"
                                    ),
                                    "cache-control": "no-cache",
                                    Accept: "application/json",
                                },
                            }
                        )
                        .then((res) => {
                            this.onlineCourses = res.data.data;
                            this.last_page = res.data.meta.last_page;
                            this.isLoading = false;
                        });
                    // ============ END:: ONLINE CORSES
                }
            } else if (this.registeredUserType == "parent") {
                if (this.currentTab == "registered_courses") {
                    this.$axios
                        .get(
                            `parent/subjects/${this.$route.params.id}/${this.$route.params.son_id}?subject_kind=record`,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        localStorage.getItem(
                                            "elmo3lm_elmosa3d_user_token"
                                        ),
                                    "Accept-language": localStorage.getItem(
                                        "elmo3lm_elmosa3d_app_lang"
                                    ),
                                    "cache-control": "no-cache",
                                    Accept: "application/json",
                                },
                            }
                        )
                        .then((res) => {
                            this.courseDetails = res.data;
                            this.registeredCourses = res.data.data;
                            this.isLoading = false;
                        });
                    // ============ END:: RECORD CORSES
                } else if (this.currentTab == "online_courses") {
                    // ============ START:: ONLINE CORSES
                    this.$axios
                        .get(
                            `parent/subjects/${this.$route.params.id}/${this.$route.params.son_id}?subject_kind=online`,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        localStorage.getItem(
                                            "elmo3lm_elmosa3d_user_token"
                                        ),
                                    "Accept-language": localStorage.getItem(
                                        "elmo3lm_elmosa3d_app_lang"
                                    ),
                                    "cache-control": "no-cache",
                                    Accept: "application/json",
                                },
                            }
                        )
                        .then((res) => {
                            this.onlineCourses = res.data.data;
                            this.isLoading = false;
                        });
                    // ============ END:: ONLINE CORSES
                }
            }
        },

        // START:: PAGENATION
        pagenationClick(pageNum) {
            this.current_page = pageNum;
            this.getCourseDetails();
        },
        // END:: PAGENATION
    },
    mounted() {
        this.getCourseDetails();
    },
};
</script>
