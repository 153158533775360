var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orders_list_content_wrapper"},[_c('Tabs',{attrs:{"tabsContent":_vm.tabsContent},on:{"getItems":_vm.getItems},scopedSlots:_vm._u([{key:"current_orders",fn:function(){return [(
                    _vm.isLoading &&
                    _vm.currentTab == 'current_orders' &&
                    _vm.current_page == 1
                )?_c('MainLoader',{staticClass:"custom_loader"}):_c('div',{staticClass:"fadeIn"},[(_vm.currentOrders.length == 0)?_c('EmptyOrdersMessage'):_c('div',{staticClass:"current_orders_wrapper"},[_c('div',{staticClass:"row"},_vm._l((_vm.currentOrders),function(order){return _c('div',{key:order.id,staticClass:"col-lg-6 my-3 fadeIn"},[_c('router-link',{staticClass:"order_card",attrs:{"to":`/order-details/${order.id}`}},[_c('h3',{staticClass:"order_title"},[_c('span',{staticClass:"order_number"},[_vm._v(" "+_vm._s(_vm.$t("TITLES.order_number"))+" "+_vm._s(order.id)+" ")]),_c('span',{staticClass:"order_date"},[_vm._v(" "+_vm._s(order.created_at)+" ")])]),_c('h4',{staticClass:"order_status"},[_c('span',{staticClass:"orders_count"},[_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(order.products.length)+" ")]),_vm._v(" "+_vm._s(_vm.$t("TITLES.products"))+" ")]),(order.status)?_c('span',{staticClass:"status",class:order.status},[(
                                                order.status == 'accepted'
                                            )?_c('span',[_vm._v(_vm._s(_vm.$t("STATUS.on_going"))+" ")]):(
                                                order.status == 'pending'
                                            )?_c('span',[_vm._v(_vm._s(_vm.$t("STATUS.pending"))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"order_price_and_thumbnails"},[_c('h3',{staticClass:"price"},[_vm._v(" "+_vm._s(order.total_with_vat)+"جنيه مصري ")]),_c('div',{staticClass:"thumbnails"},_vm._l((order.products),function(thumbnail){return _c('div',{key:thumbnail.id,staticClass:"thumbnail_wrapper"},[_c('img',{attrs:{"src":thumbnail.product_image,"width":"50","height":"50","alt":thumbnail.product}})])}),0)])])],1)}),0)]),_c('Pagenation',{attrs:{"last_page":_vm.last_page,"current_page":_vm.current_page},on:{"pagenationClick":_vm.pagenationClick}})],1)]},proxy:true},(_vm.currentTab == 'finished_orders')?{key:"finished_orders",fn:function(){return [(
                    _vm.isLoading &&
                    _vm.currentTab == 'finished_orders' &&
                    _vm.current_page == 1
                )?_c('MainLoader',{staticClass:"custom_loader"}):_c('div',{staticClass:"fadeIn"},[(_vm.finishedOrders.length == 0)?_c('EmptyOrdersMessage'):_c('div',{staticClass:"finished_orders_wrapper"},[_c('div',{staticClass:"row"},_vm._l((_vm.finishedOrders),function(order){return _c('div',{key:order.id,staticClass:"col-lg-6 my-3 fadeIn"},[_c('router-link',{staticClass:"order_card",attrs:{"to":`/order-details/${order.id}`}},[_c('h3',{staticClass:"order_title"},[_c('span',{staticClass:"order_number"},[_vm._v(" "+_vm._s(_vm.$t("TITLES.order_number"))+" "+_vm._s(order.id)+" ")]),_c('span',{staticClass:"order_date"},[_vm._v(" "+_vm._s(order.created_at)+" ")])]),_c('h4',{staticClass:"order_status"},[_c('span',{staticClass:"orders_count"},[_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(order.products.length)+" ")]),_vm._v(" "+_vm._s(_vm.$t("TITLES.products"))+" ")]),(order.status)?_c('span',{staticClass:"status",class:order.status},[(order.status == 'accept')?_c('span',[_vm._v(_vm._s(_vm.$t("STATUS.accepted"))+" ")]):_vm._e(),(order.status == 'reject')?_c('span',[_vm._v(_vm._s(_vm.$t("STATUS.canceled"))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"order_price_and_thumbnails"},[_c('h3',{staticClass:"price"},[_vm._v(" "+_vm._s(order.total_with_vat)+"جنيه مصري ")]),_c('div',{staticClass:"thumbnails"},_vm._l((order.products),function(thumbnail){return _c('div',{key:thumbnail.id,staticClass:"thumbnail_wrapper"},[_c('img',{attrs:{"src":thumbnail.product_image,"width":"50","height":"50","alt":"Order Image"}})])}),0)])])],1)}),0)]),_c('Pagenation',{attrs:{"last_page":_vm.last_page,"current_page":_vm.current_page},on:{"pagenationClick":_vm.pagenationClick}})],1)]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }