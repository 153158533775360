<template>
    <div class="about_us_section_wrapper" v-if="aboutUsData">
        <div class="container">
            <div class="row">
                <!-- START:: SECTION TEXT -->
                <div class="col-lg-8">
                    <div class="section_title_wrapper">
                        <h2 class="title">
                            <img
                                src="../../assets/media/shapes/title_icon.svg"
                                alt="Title Icon"
                            />
                            <span> {{ $t("TITLES.about_us") }} </span>
                        </h2>
                    </div>

                    <div class="section_subtitle_wrapper">
                        <h3 class="subtitle">{{ $t("TITLES.futurezoon") }}</h3>
                    </div>

                    <div class="section_text" v-html="aboutUsData"></div>

                    <div class="section_route">
                        <router-link to="/about-us">
                            {{ $t("BUTTONS.more") }}
                        </router-link>
                    </div>
                </div>
                <!-- END:: SECTION TEXT -->

                <!-- START:: SECTION IMAGE -->
                <div class="col-lg-4 p-0 d-none d-lg-block">
                    <!-- START:: SECTION IMAGE WRAPPER -->
                    <div class="border_wrapper">
                        <div class="section_image_wrapper"></div>
                    </div>
                    <!-- END:: SECTION IMAGE WRAPPER -->
                </div>
                <!-- END:: SECTION IMAGE -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutUsSec",

    props: ["aboutUsData"],
};
</script>
