import StoreAndWishlistMutations from "./mutations.js";
import StoreAndWishlistActions from "./actions.js";
import StoreAndWishlistGetters from "./getters.js";

import Item_1_Image from "../../../assets/media/images/store/item1.png";
import Item_2_Image from "../../../assets/media/images/store/item2.png";
import Item_3_Image from "../../../assets/media/images/store/item3.png";
import Item_4_Image from "../../../assets/media/images/store/item4.png";

import cardImage from "../../../assets/media/images/course_card.png";
import cardImageTeacher from "../../../assets/media/images/teacher_card.png";
import instructorImage from "../../../assets/media/images/user_avatar.png";

export default {
    namespaced: true,

    state: {
        // =================== START:: STORE DATA ===================
        // START:: STORE ITEMS
        storeItems: [],
        last_page: null,
        // END:: STORE ITEMS

        // START:: SHOPPING CART ITEMS
        shoppingCartItems: [],
        show_cash: false,
        // END:: SHOPPING CART ITEMS
        // =================== END:: STORE DATA ===================

        // =================== START:: WISHLIST DATA ===================
        // START:: FAVORITES
        favorites: {
            registeredSubjects: [
                {
                    id: 1,
                    image: cardImage,
                    name: "الرياضيات",
                    duration: {
                        hours: 6,
                        minutes: 40,
                    },
                    instructor: {
                        image: instructorImage,
                        name: "عبد الله جابر محرم",
                        rate: 4.3,
                    },
                },
                {
                    id: 2,
                    image: cardImage,
                    name: "الرياضيات",
                    duration: {
                        hours: 6,
                        minutes: 40,
                    },
                    instructor: {
                        image: instructorImage,
                        name: "عبد الله جابر محرم",
                        rate: 4.3,
                    },
                },
                {
                    id: 3,
                    image: cardImage,
                    name: "الرياضيات",
                    duration: {
                        hours: 6,
                        minutes: 40,
                    },
                    instructor: {
                        image: instructorImage,
                        name: "عبد الله جابر محرم",
                        rate: 4.3,
                    },
                },
                {
                    id: 4,
                    image: cardImage,
                    name: "الرياضيات",
                    duration: {
                        hours: 6,
                        minutes: 40,
                    },
                    instructor: {
                        image: instructorImage,
                        name: "عبد الله جابر محرم",
                        rate: 4.3,
                    },
                },
                {
                    id: 5,
                    image: cardImage,
                    name: "الرياضيات",
                    duration: {
                        hours: 6,
                        minutes: 40,
                    },
                    instructor: {
                        image: instructorImage,
                        name: "عبد الله جابر محرم",
                        rate: 4.3,
                    },
                },
            ],
            onlineSubjects: [
                {
                    id: 1,
                    image: cardImage,
                    name: "الرياضيات",
                    price: 300,
                    startDate: " 24 يناير 2022 ",
                    instructor: {
                        image: instructorImage,
                        name: "عبد الله جابر محرم",
                        rate: 4.3,
                    },
                },
                {
                    id: 2,
                    image: cardImage,
                    name: "الرياضيات",
                    price: 300,
                    startDate: " 24 يناير 2022 ",
                    instructor: {
                        image: instructorImage,
                        name: "عبد الله جابر محرم",
                        rate: 4.3,
                    },
                },
            ],
            teacherSubjects: [
                {
                    id: 1,
                    image: cardImageTeacher,
                    name: "دورة الدراسات العليا",
                    duration: {
                        hours: 6,
                        minutes: 40,
                    },
                    rate: 4.5,
                    price: 300,
                },
                {
                    id: 2,
                    image: cardImageTeacher,
                    name: "دورة الدراسات العليا",
                    duration: {
                        hours: 6,
                        minutes: 40,
                    },
                    rate: 4.5,
                    price: 300,
                },
                {
                    id: 3,
                    image: cardImageTeacher,
                    name: "دورة الدراسات العليا",
                    duration: {
                        hours: 6,
                        minutes: 40,
                    },
                    rate: 4.5,
                    price: 300,
                },
                {
                    id: 4,
                    image: cardImageTeacher,
                    name: "دورة الدراسات العليا",
                    duration: {
                        hours: 6,
                        minutes: 40,
                    },
                    rate: 4.5,
                    price: 300,
                },
            ],
            products: [
                {
                    id: 1,
                    image: Item_1_Image,
                    name: "قلم إسود",
                    price: "300",
                    desc: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم",
                    quantity: 30,
                    quantityInCart: 0,
                    isFavorite: false,
                },
                {
                    id: 2,
                    image: Item_2_Image,
                    name: "حقيبة أقلام",
                    price: "300",
                    desc: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم",
                    quantity: 30,
                    quantityInCart: 0,
                    isFavorite: false,
                },
                {
                    id: 3,
                    image: Item_3_Image,
                    name: "زجاجة مياه ",
                    price: "300",
                    desc: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم",
                    quantity: 30,
                    quantityInCart: 0,
                    isFavorite: false,
                },
                {
                    id: 4,
                    image: Item_4_Image,
                    name: "شنطة مدرسة سوداء",
                    price: "300",
                    desc: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم",
                    quantity: 30,
                    quantityInCart: 0,
                    isFavorite: false,
                },
            ],
        },
        // END:: FAVORITES
        // =================== END:: WISHLIST DATA ===================
    },

    mutations: StoreAndWishlistMutations,
    actions: StoreAndWishlistActions,
    getters: StoreAndWishlistGetters,
};
