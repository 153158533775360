<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->
        <div class="order_details_content_wrapper fadeIn" v-else>
            <!-- START:: TITLE -->
            <h2 class="order_title">
                <span class="order_number">
                    {{ $t("TITLES.order_number") }} {{ courseDetails.id }}
                </span>
                <span class="order_date"> {{ courseDetails.created_at }} </span>
            </h2>
            <!-- END:: TITLE -->

            <!-- START:: STATUS -->
            <h4 class="order_status">
                <span class="orders_count">
                    <span class="number">
                        {{ courseDetails.products.length }}
                    </span>
                    {{ $t("TITLES.products") }}
                </span>

                <span class="status" :class="courseDetails.status">
                    <span v-if="courseDetails.status == 'accepted'"
                        >{{ $t("STATUS.on_going") }}
                    </span>
                    <span v-else-if="courseDetails.status == 'pending'"
                        >{{ $t("STATUS.pending") }}
                    </span>
                </span>
            </h4>
            <!-- END:: STATUS -->

            <!-- START:: ORDER ITEMS CARDS -->
            <div class="orders_items_cards_wrapper">
                <div class="row">
                    <!-- START:: ITEM CARD -->
                    <div
                        v-for="product in courseDetails.products"
                        :key="product.id"
                        class="col-lg-4 my-3"
                    >
                        <div class="order_item_card_wrapper">
                            <div class="item_details">
                                <!-- START:: ITEM IMAGE -->
                                <div class="image_wrapper">
                                    <img
                                        :src="product.product_image"
                                        :alt="product.product"
                                        width="120"
                                        height="120"
                                    />
                                </div>
                                <!-- END:: ITEM IMAGE -->

                                <div class="wrapper">
                                    <!-- START:: ITEM NAME AND QUANTITY -->
                                    <h3 class="name_and_quantity">
                                        <span class="name">
                                            {{ product.product }}
                                        </span>
                                        <span class="quantity">
                                            {{ product.count }}X
                                        </span>
                                    </h3>
                                    <!-- END:: ITEM NAME AND QUANTITY -->

                                    <!-- START:: ITEM PRICE -->
                                    <h3 class="price">
                                        {{ product.total }}جنيه مصري
                                    </h3>
                                    <!-- END:: ITEM PRICE -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END:: ITEM CARD -->
                </div>
            </div>
            <!-- END:: ORDER ITEMS CARDS -->

            <div class="row">
                <!-- START:: DELIVER INFO WRAPPER -->
                <div class="col-lg-6 my-3">
                    <div class="deliver_info_data_wrapper">
                        <!-- START:: TITLE -->
                        <h3 class="section_title">
                            {{ $t("TITLES.delivery_info") }}
                        </h3>
                        <!-- END:: TITLE -->

                        <!-- START:: ADDRESS -->
                        <div class="deliver_address">
                            <h4 class="deliver_address_title">
                                {{ $t("TITLES.address") }}
                            </h4>
                            <h4 class="address">
                                <span class="icon">
                                    <i class="fal fa-map-marker-alt"></i>
                                </span>
                                <span class="text">
                                    {{ courseDetails.street }} -
                                    {{ courseDetails.neighborhood }} -
                                    {{ courseDetails.city }}
                                </span>
                            </h4>
                        </div>
                        <!-- END:: ADDRESS -->

                        <!-- START:: PHONE -->
                        <div class="deliver_phone">
                            <h4 class="deliver_phone_title">
                                {{ $t("TITLES.another_phone") }}
                            </h4>
                            <h4 class="phone">
                                <span class="icon">
                                    <i class="fal fa-mobile"></i>
                                </span>
                                <span class="text" dir="ltr">
                                    {{ courseDetails.phone }}
                                </span>
                            </h4>
                        </div>
                        <!-- END:: PHONE -->
                    </div>
                </div>
                <!-- END:: DELIVER INFO WRAPPER -->

                <!-- START:: ORDER FINANCE WRAPPER -->
                <div class="col-lg-6 my-3">
                    <div class="order_finance_wrapper">
                        <!-- START:: TITLE -->
                        <h3 class="section_title">
                            {{ $t("TITLES.price_details") }}
                        </h3>
                        <!-- END:: TITLE -->

                        <!-- START:: CART FINANCE LINE -->
                        <div class="order_finance_line">
                            <h3>
                                <span class="title">
                                    {{ $t("TITLES.products_total") }}
                                </span>
                                <span class="price">
                                    {{ courseDetails.total }}جنيه مصري
                                </span>
                            </h3>
                        </div>
                        <!-- END:: CART FINANCE LINE -->

                        <!-- START:: CART FINANCE LINE -->
                        <div class="order_finance_line">
                            <h3>
                                <span class="title">
                                    {{ $t("TITLES.added_tax") }}
                                </span>
                                <span class="price">
                                    {{ courseDetails.vat_value }}%
                                </span>
                            </h3>
                        </div>
                        <!-- END:: CART FINANCE LINE -->

                        <!-- START:: CART FINANCE LINE -->
                        <div class="order_finance_line">
                            <h3>
                                <span class="title">
                                    {{ $t("TITLES.total") }}
                                </span>
                                <span class="price">
                                    {{ courseDetails.total_with_vat }}جنيه مصري
                                </span>
                            </h3>
                        </div>
                        <!-- END:: CART FINANCE LINE -->
                    </div>
                </div>
                <!-- END:: ORDER FINANCE WRAPPER -->
            </div>
        </div>
    </div>
</template>

<script>
// END:: IMPORTING MODALS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
export default {
    name: "OrderDetails",

    data() {
        return {
            // START:: ORDER ID
            orderId: this.$route.params.id,
            // END:: ORDER ID

            // START:: COURSE DETAILS
            courseDetails: null,
            // END:: COURSE DETAILS
            isLoading: false,
        };
    },

    methods: {
        // START:: GET ORDER DETAILS
        getOrderDetails() {
            this.isLoading = true;
            this.$axios
                .get(`user/order/${this.orderId}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.courseDetails = res.data.data;
                    this.isLoading = false;
                });
        },
        // END:: GET ORDER DETAILS
    },

    mounted() {
        // START:: FIRE METHODS
        this.getOrderDetails();
        // END:: FIRE METHODS
    },
    components: {
        MainLoader,
    },
};
</script>
