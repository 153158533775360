<template>
    <div class="add_subject_steps_wrapper">
        <div class="add_subject_steps_card_wrapper">
            <div class="container">
                <!-- START:: FORM -->
                <form @submit.prevent="stepOneValidate">
                    <div class="row justify-content-center">
                        <!-- START:: SUBJECT UPLOADED INPUT -->
                        <div class="col-12 my-3">
                            <div class="single_image_input_wrapper">
                                <div class="wrapper">
                                    <label for="user_avatar">
                                        <!-- <i class="fal fa-camera-alt"></i> -->
                                    </label>
                                    <img
                                        class="user_avatar"
                                        :src="addSubject.image.bath"
                                        alt="User Avatar"
                                        width="150"
                                        height="150"
                                    />
                                    <input
                                        type="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        class="form-control"
                                        id="user_avatar"
                                        :placeholder="
                                            $t('PLACEHOLDERS.student_code')
                                        "
                                        @change="selectUploadedImage"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- END:: SUBJECT UPLOADED INPUT -->

                        <!-- START:: STUDY DEGREE INPUT GROUP -->
                        <div class="my-3 col-md-6">
                            <div class="input_wrapper">
                                <select
                                    class="form-select"
                                    v-model.trim="addSubject.degree"
                                    @change="
                                        getSubjectsName(
                                            addSubject.degree.degree.id
                                        );
                                        getStudyYears(
                                            addSubject.degree.degree.id
                                        );
                                        addSubject.name = null;
                                        addSubject.studyYear = null;
                                    "
                                >
                                    <option selected disabled :value="null">
                                        {{ $t("PLACEHOLDERS.study_degree") }}
                                    </option>
                                    <option
                                        v-for="degree in studyDegrees"
                                        :key="degree.id"
                                        :value="degree"
                                    >
                                        {{ degree.degree.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- END:: STUDY DEGREE INPUT GROUP -->

                        <!-- START:: SUBJECT NAME INPUT GROUP -->
                        <div class="my-3 col-md-6">
                            <div class="input_wrapper">
                                <select
                                    class="form-select"
                                    v-model.trim="addSubject.name"
                                >
                                    <option selected disabled :value="null">
                                        {{ $t("PLACEHOLDERS.subject_name") }}
                                    </option>
                                    <option
                                        v-for="subjectName in subjectsNames"
                                        :key="subjectName.id"
                                        :value="subjectName.id"
                                    >
                                        {{ subjectName.data.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- END:: SUBJECT NAME INPUT GROUP -->

                        <!-- START:: STUDY YEAR INPUT GROUP -->
                        <div class="my-3 col-md-6">
                            <div class="input_wrapper">
                                <select
                                    class="form-select"
                                    v-model.trim="addSubject.studyYear"
                                >
                                    <option selected disabled :value="null">
                                        {{ $t("PLACEHOLDERS.study_year") }}
                                    </option>
                                    <option
                                        v-for="year in studyYears"
                                        :key="year.id"
                                        :value="year.id"
                                    >
                                        {{ year.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- END:: STUDY YEAR INPUT GROUP -->

                        <!-- START:: SUBJECT CATEGORY INPUT GROUP -->
                        <div class="my-3 col-md-6">
                            <div class="input_wrapper">
                                <select
                                    class="form-select"
                                    v-model.trim="addSubject.subjectCat"
                                >
                                    <option selected disabled :value="null">
                                        {{ $t("PLACEHOLDERS.subject_cat") }}
                                    </option>
                                    <option
                                        v-for="cat in subjectCats"
                                        :key="cat.id"
                                        :value="cat.id"
                                    >
                                        {{ cat.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- END:: SUBJECT CATEGORY INPUT GROUP -->

                        <!-- START:: LANGUAGE USED SELECT GROUP -->
                        <div class="my-3 col-md-6">
                            <div class="input_wrapper">
                                <select
                                    class="form-select"
                                    v-model.trim="addSubject.language"
                                >
                                    <option selected disabled :value="null">
                                        {{ $t("PLACEHOLDERS.used_language") }}
                                    </option>
                                    <option value="ar">
                                        {{ $t("PLACEHOLDERS.arabic") }}
                                    </option>
                                    <option value="en">
                                        {{ $t("PLACEHOLDERS.english") }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- END:: LANGUAGE USED SELECT GROUP -->

                        <!-- START:: DATEPICKER INPUT GROUP -->
                        <div class="my-3 col-md-6">
                            <a-date-picker
                                :placeholder="$t('PLACEHOLDERS.start_date')"
                                format="YYYY-MM-DD"
                                valueFormat="YYYY-MM-DD"
                                :disabled-date="disabledDate"
                                v-model="addSubject.start_date"
                            />
                        </div>
                        <!-- END:: DATEPICKER INPUT GROUP -->

                        <!-- START:: HOUR COUNT INPUT GROUP -->
                        <div class="my-3 col-md-6">
                            <div class="input_wrapper">
                                <input
                                    type="number"
                                    class="form-control"
                                    :placeholder="$t('PLACEHOLDERS.hour_count')"
                                    v-model.trim="addSubject.hour_count"
                                />
                            </div>
                        </div>
                        <!-- END:: HOUR COUNT INPUT GROUP -->

                        <!-- START:: TYPE SELECT GROUP -->
                        <div class="col-md-6 my-3">
                            <div class="input_wrapper">
                                <select
                                    class="form-select"
                                    v-model.trim="addSubject.type_subject"
                                >
                                    <option disabled selected value="null">
                                        {{ $t("PLACEHOLDERS.subject_type") }}
                                    </option>
                                    <option value="online">
                                        {{ $t("PLACEHOLDERS.online") }}
                                    </option>
                                    <option value="record">
                                        {{ $t("PLACEHOLDERS.record") }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- END:: TYPE SELECT GROUP -->

                        <!-- START:: PRICE INPUT GROUP -->
                        <div
                            class="my-3 col-md-6"
                            v-if="addSubject.type_subject == 'online'"
                        >
                            <div class="input_wrapper">
                                <input
                                    type="number"
                                    class="form-control"
                                    :placeholder="
                                        $t('PLACEHOLDERS.price_subject')
                                    "
                                    v-model.trim="addSubject.price_subject"
                                />
                            </div>
                        </div>
                        <!-- END:: PRICE INPUT GROUP -->

                        <!-- START:: DESCRIPTION GROUP -->
                        <div class="my-3 col-md-6">
                            <div class="input_wrapper">
                                <textarea
                                    class="form-control"
                                    :placeholder="
                                        $t('PLACEHOLDERS.desc_subject')
                                    "
                                    v-model.trim="addSubject.desc_subject"
                                    rows="4"
                                ></textarea>
                            </div>
                        </div>
                        <!-- END:: DESCRIPTION GROUP -->

                        <!-- START:: GOALS SUBJECT GROUP -->
                        <div class="my-3 col-md-6">
                            <div class="input_wrapper">
                                <textarea
                                    class="form-control"
                                    :placeholder="
                                        $t('PLACEHOLDERS.goals_subject')
                                    "
                                    v-model.trim="addSubject.goals_subject"
                                    rows="4"
                                ></textarea>
                            </div>
                        </div>
                        <!-- END:: GOALS SUBJECT GROUP -->

                        <!-- START:: ENRICHMENTS GROUP -->
                        <div class="my-3 col-md-6">
                            <div class="input_wrapper">
                                <textarea
                                    class="form-control"
                                    :placeholder="
                                        $t('PLACEHOLDERS.course_enrichments')
                                    "
                                    v-model.trim="addSubject.course_enrichments"
                                    rows="4"
                                ></textarea>
                            </div>
                        </div>
                        <!-- END:: ENRICHMENTS GROUP -->

                        <!-- START:: OFFER CHICK INPUT -->
                        <div class="my-3 col-12">
                            <div
                                class="input_wrapper switch_wrapper"
                                dir="ltr"
                                v-if="addSubject.type_subject == 'online'"
                            >
                                <h5 class="switch_label">
                                    يوجد عروض علي المادة؟
                                </h5>
                                <v-switch
                                    v-model="addSubject.has_offer"
                                    color="success"
                                    hide-details
                                >
                                </v-switch>
                            </div>
                        </div>
                        <!-- END:: OFFER CHICK INPUT -->

                        <!-- START:: OFFER DESC -->
                        <div
                            class="my-3 col-md-6"
                            v-show="addSubject.has_offer"
                        >
                            <div class="input_wrapper">
                                <textarea
                                    class="form-control"
                                    :placeholder="$t('PLACEHOLDERS.offer_desc')"
                                    v-model.trim="addSubject.offer_desc"
                                    rows="4"
                                ></textarea>
                            </div>
                        </div>
                        <!-- END:: OFFER DESC -->

                        <!-- START:: OFFER AMOUNT -->
                        <div
                            class="my-3 col-md-6"
                            v-show="addSubject.has_offer"
                        >
                            <div class="input_wrapper">
                                <input
                                    type="number"
                                    class="form-control"
                                    :placeholder="
                                        $t('PLACEHOLDERS.offer_amount')
                                    "
                                    v-model.trim="addSubject.offer_amount"
                                />
                            </div>
                        </div>
                        <!-- END:: OFFER AMOUNT -->

                        <!-- START:: MULTI UPLOAD FILES GROUP -->
                        <div class="my-3 col-12">
                            <div class="page_small_title">
                                <h3>{{ $t("TITLES.attachments") }}</h3>
                            </div>

                            <div class="input_wrapper multiple_upload_file">
                                <div class="file_preview_wrapper">
                                    <div
                                        v-for="attachment in addSubject.attachmentsFiles"
                                        :key="attachment.id"
                                        class="file_preview_card"
                                    >
                                        <div class="card_overlay">
                                            <button
                                                type="button"
                                                class="remove_preview_card_btn"
                                                @click="
                                                    removePreviewCard(
                                                        attachment.id
                                                    )
                                                "
                                            >
                                                <i class="fal fa-trash-alt"></i>
                                            </button>
                                        </div>

                                        <span class="file_icon">
                                            <i class="fal fa-file-alt"></i>
                                        </span>

                                        <span class="file_name">
                                            {{ attachment.file.name }}
                                        </span>
                                    </div>
                                </div>

                                <div class="label_and_input_wrapper">
                                    <input
                                        id="course_multiple_files"
                                        type="file"
                                        accept=".txt,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        multiple
                                        @change="
                                            handleUploadAttachments($event)
                                        "
                                    />
                                    <label for="course_multiple_files">
                                        <i class="fal fa-plus"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- START:: MULTI UPLOAD FILES GROUP -->

                        <!-- START:: SUBMIT BUTTON WRAPPER -->
                        <div class="col-lg-7 my-3">
                            <div class="btn_wrapper">
                                <button class="w-100 mt-0">
                                    {{ $t("BUTTONS.next") }}
                                    <span
                                        class="btn_loader"
                                        v-if="isWaitingRequest"
                                    ></span>
                                </button>
                            </div>
                        </div>
                        <!-- END:: SUBMIT BUTTON WRAPPER -->
                    </div>
                </form>
                <!-- END:: FORM -->
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING MOMENT
import moment from "moment";
// END:: IMPORTING MOMENT

import image_path from "../../assets/media/images/upload_image.png";

export default {
    name: "addSubjectStepOne",

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: SELECTIONS DATA
            studyDegrees: null,
            subjectsNames: null,
            studyYears: null,
            subjectCats: null,
            // END:: SELECTIONS DATA

            // START:: ADD SUBJECT DATA
            addSubject: {
                image: {
                    bath: image_path,
                    file: null,
                },
                degree: null,
                name: null,
                studyYear: null,
                subjectCat: null,
                language: null,
                type_subject: null,
                start_date: null,
                hour_count: null,
                price_subject: null,
                desc_subject: null,
                goals_subject: null,
                course_enrichments: null,
                has_offer: false,
                attachmentsFiles: [],
                attachmentsFilesPreview: [],
                offer_desc: null,
                offer_amount: null,
            },
            // END:: ADD SUBJECT DATA
            previewVisible: false,
            previewImage: "",
            fileList: [],
        };
    },

    methods: {
        // START:: VUEX METHODS
        ...mapActions("CreateCourseModule", ["setCrateCourseStepOneData"]),
        // END:: VUEX METHODS

        // START:: GET DEGREES
        getStudyDegrees() {
            this.$axios
                .get("teacher/all_degrees", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.studyDegrees = res.data.data;
                });
        },
        // END:: GET DEGREES

        // START:: GET SUBJECTS NAME
        getSubjectsName(id) {
            this.$axios
                .get(`teacher/all-subject-name/${id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.subjectsNames = res.data.data;
                    console.log(this.subjectsNames);
                });
        },
        // END:: GET SUBJECTS NAME

        // START:: GET STUDY YEAR
        getStudyYears(id) {
            this.$axios
                .get(`teacher/academic_years/${id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.studyYears = res.data.data;
                });
        },
        // END:: GET STUDY YEAR

        // START:: GET STUDY YEAR
        getSubjectCats() {
            this.$axios
                .get("teacher/categories", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("elmo3lm_elmosa3d_user_token"),
                        "Accept-language": localStorage.getItem(
                            "elmo3lm_elmosa3d_app_lang"
                        ),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.subjectCats = res.data.data;
                });
        },
        // END:: GET STUDY YEAR

        // START:: VALIDATE STEP ONE
        stepOneValidate() {
            this.isWaitingRequest = true;
            if (!this.addSubject.image.file) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.subject_image"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            }
            // else if (!this.addSubject.name) {
            //     this.isWaitingRequest = false;
            //     this.$iziToast.error({
            //         timeout: 2000,
            //         message: this.$t("VALIDATION.name_subject"),
            //         messageSize: "22",
            //         position: this.$t("iziToastConfigs.position"),
            //         rtl: this.$t("iziToastConfigs.dir"),
            //     });
            //     return;
            // }
            else if (!this.addSubject.subjectCat) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.subject_cat"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.addSubject.language) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.language"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.addSubject.type_subject) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.type_subject"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.addSubject.start_date) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.start_date"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.addSubject.hour_count) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.hour_count"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (
                this.addSubject.type_subject == "online" &&
                !this.addSubject.price_subject
            ) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.price_subject"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.addSubject.desc_subject) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.desc_subject"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.addSubject.goals_subject) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.goals_subject"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.submitStepOneForm();
            }
        },
        // END:: VALIDATE STEP ONE

        // START:: SUBMIT STEP ONE FORM
        submitStepOneForm() {
            this.isWaitingRequest = false;
            this.setCrateCourseStepOneData(this.addSubject);
            this.$router.push(`/add-subject/step-two`);
            localStorage.setItem("subjectType", this.addSubject.type_subject);
        },
        // END:: SUBMIT STEP ONE FORM

        // START:: SELECT UPLOADED IMAGE
        selectUploadedImage(e) {
            this.addSubject.image.bath = URL.createObjectURL(e.target.files[0]);
            this.addSubject.image.file = e.target.files[0];
        },
        // END:: SELECT UPLOADED IMAGE

        // START:: HANDLE UPLOAD ATTACHMENTS
        handleUploadAttachments(e) {
            console.log(e);

            let selectedFiles = Array.from(e.target.files); // Convert FileList to an array
            console.log(selectedFiles);

            selectedFiles.forEach((file) => {
                this.addSubject.attachmentsFiles.push({
                    id: Math.floor(Math.random() * 100),
                    file: file,
                });
            });
        },
        // END:: HANDLE UPLOAD ATTACHMENTS

        // START:: REMOVE PREVIEW CARD
        removePreviewCard(cardId) {
            let targetElement = this.addSubject.attachmentsFiles.find(
                (element) => element.id == cardId
            );
            let targetElementIndex =
                this.addSubject.attachmentsFiles.indexOf(targetElement);
            this.addSubject.attachmentsFiles.splice(targetElementIndex, 1);
        },
        // END:: REMOVE PREVIEW CARD

        // START:: DATEPICKER DISABLE PREVIOUS DAYS METHOD
        disabledDate(current) {
            return current && current < moment().startOf("day");
        },
        // END:: DATEPICKER DISABLE PREVIOUS DAYS METHOD
    },

    mounted() {
        // START:: FIRE METHODS
        this.getStudyDegrees();
        // this.getSubjectsName();
        // this.getStudyYears();
        this.getSubjectCats();
        // END:: FIRE METHODS
    },
};
</script>
