<template>
    <div>
        <!-- START:: MAIN LOADER -->
        <MainLoader v-if="isLoading" />
        <!-- END:: MAIN LOADER -->

        <div class="course_payment_wrapper" v-else>
            <!-- START:: IMAGE HEADER -->
            <ImageHeader>
                <template>
                    <div class="header_container">
                        <div class="container">
                            <div class="header_title_wrapper">
                                <h1>
                                    {{
                                        courseDetails?.subject_name?.subject_name
                                    }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </template>
            </ImageHeader>
            <!-- END:: IMAGE HEADER -->

            <div class="payment_content_wrapper">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <!-- START:: COURSE PAYMENT CARD -->
                            <div class="course_payment_card">
                                <div class="card_content_wrapper">
                                    <h3 class="name">
                                        {{
                                            courseDetails.subject_name
                                                .subject_name
                                        }}
                                    </h3>
                                    <h3 class="duration">
                                        <span
                                            >{{
                                                courseDetails.diff_of_time.day
                                            }}
                                            {{ $t("TITLES.day") }}
                                        </span>
                                        <span
                                            v-if="
                                                courseDetails.diff_of_time
                                                    .hour != 0
                                            "
                                            >{{
                                                courseDetails.diff_of_time.hour
                                            }}
                                            {{ $t("TITLES.hour") }}
                                        </span>
                                        <span
                                            v-if="
                                                courseDetails.diff_of_time
                                                    .minute != 0
                                            "
                                            >{{
                                                courseDetails.diff_of_time
                                                    .minute
                                            }}
                                            {{ $t("TITLES.minute") }}
                                        </span>
                                        <span
                                            v-if="
                                                courseDetails.diff_of_time
                                                    .second != 0
                                            "
                                            >{{
                                                courseDetails.diff_of_time
                                                    .second
                                            }}
                                            {{ $t("TITLES.second") }}
                                        </span>
                                    </h3>
                                </div>

                                <div class="card_content_wrapper">
                                    <h3 class="price_title">سعر المادة</h3>
                                    <h3 class="price">
                                        {{ courseDetails.price }}
                                        {{ courseDetails.currency }}
                                    </h3>
                                </div>
                            </div>
                            <!-- END:: COURSE PAYMENT CARD -->

                            <!-- START:: ROUTER VIEW -->
                            <div class="payment_forms_wrapper">
                                <router-view></router-view>
                            </div>
                            <!-- END:: ROUTER VIEW -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING LOADERS
import MainLoader from "../../components/ui/loaders/MainLoader.vue";
// END:: IMPORTING LOADERS
// START:: IMPORTING IMAGE HEADER
import ImageHeader from "../../components/ui/ImageHeader.vue";
// END:: IMPORTING IMAGE HEADER

export default {
    name: "CoursePayment",
    computed: {},
    data() {
        return {
            // START:: USER TYPE
            userType: localStorage.getItem("elmo3lm_elmosa3d_user_type"),
            // END:: USER TYPE

            courseDetails: null,
            isLoading: false,
        };
    },

    methods: {
        getCourseDetails() {
            this.isLoading = true;
            if (this.userType == "teacher") {
                this.$axios
                    .get(`teacher/category/subject/${this.$route.params.id}`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.courseDetails = res.data.data;
                    });
            } else if (this.userType == "student") {
                this.$axios
                    .get(`student/course/${this.$route.params.id}`, {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                    "elmo3lm_elmosa3d_user_token"
                                ),
                            "Accept-language": localStorage.getItem(
                                "elmo3lm_elmosa3d_app_lang"
                            ),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isLoading = false;
                        this.courseDetails = res.data.data;
                    });
            }
        },
    },
    mounted() {
        this.getCourseDetails();
    },
    components: {
        MainLoader,
        ImageHeader,
    },
};
</script>
