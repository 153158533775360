import Vue from "vue";
import VueRouter from "vue-router";

// START:: IMPORTING MIDDLEWARE
import auth from "../middleware/auth.js";
// END:: IMPORTING MIDDLEWARE

// START:: IMPORTING ROUTER COMPONENTS
import Authentication from "../pages/Authentication.vue";
import LoginForm from "../components/authentication/LoginForm.vue";
import ResetPasswordPhoneNumberForm from "../components/authentication/ResetPasswordPhoneNumberForm.vue";
import PhoneVerification from "../components/authentication/PhoneVerificationForm.vue";
import ChangePhoneNumberForm from "../components/authentication/ChangePhoneNumber.vue";
import ResetPasswordForm from "../components/authentication/ResetPasswordForm.vue";
import SelectRegisterationType from "../components/authentication/SelectRegisterType.vue";
import RegisterFirstStepForm from "../components/authentication/RegisterFirstStep.vue";
import RegisterSecondStepForm from "../components/authentication/RegisterSecondStep.vue";
import AppContentWrapper from "../pages/AppContentWrapper.vue";
import HomePage from "../pages/HomePage.vue";
import AboutUsPage from "../pages/staticPages/AboutUsPage.vue";
import PrivacyPolicyPage from "../pages/staticPages/PrivacyPolicyPage.vue";
import TermsAndConditionsPage from "../pages/staticPages/TermsAndConditionsPage.vue";
import reportsPage from "../pages/staticPages/reportsPage.vue";
import AddReport from "../pages/AddReport.vue";
import Quiz from "../pages/Quiz.vue";
import AllQuizes from "../pages/ShowQuiz.vue";
import UserAccount from "../pages/UserAccount.vue";
import PersonalInfoForm from "../components/editUserAccountForms/PersonalInfoForm.vue";
import CareerInfoForm from "../components/editUserAccountForms/CareerInfoForm.vue";
import SelectChatMessage from "../components/ui/emptyMessages/SelectChatMessage.vue";
import Chats from "../pages/Chats.vue";
import ChatsList from "../components/conversation/ChatsList.vue";
import ChatContent from "../components/conversation/ChatContent.vue";
import StrengtheningRequests from "../pages/StrengtheningRequests.vue";
import Sons from "../pages/parent/Sons.vue";
import CoursesSection from "../pages/courses/CoursesSection.vue";
import StrengtheningRequestsCourses from "../pages/courses/StrengtheningRequestsCourses.vue";
import CoursesCategories from "../pages/courses/CoursesCategories.vue";
import CourseDetails from "../pages/courses/CourseDetails.vue";
import Courses from "../pages/courses/Courses.vue";
import AddedCourses from "../pages/courses/AddedCourses.vue";
import MyCourses from "../pages/courses/MyCourses.vue";
import VideosList from "../pages/courses/VideosList.vue";
import CoursePayment from "../pages/courses/CoursePayment.vue";
import CourseRegisterPayment from "../components/payment/CourseRegisterPayment.vue";
import PaymentForm from "../components/payment/PaymentForm.vue";
import Favorites from "../pages/Favorites.vue";
import TheStore from "../pages/store/TheStore.vue";
import MyOrders from "../pages/MyOrders.vue";
import OrdersList from "../components/orders/OrdersList.vue";
import OrderDetails from "../components/orders/OrderDetails.vue";
import ShoppingCart from "../pages/store/ShoppingCart.vue";
import Checkout from "../pages/store/Checkout.vue";
import DeliveryInfo from "../components/payment/DeliveryInfo.vue";
import TeacherBalance from "../pages/TeacherBalance.vue";
import Withdraw from "../pages/Withdraw.vue";
import AddSubject from "../pages/AddSubject.vue";
import editCourse from "../pages/courses/EditCourse.vue";
import addSubjectStepOne from "../components/addSubject/addSubjectStepOne.vue";
import addSubjectStepTwo from "../components/addSubject/addSubjectStepTwo.vue";
import addSubjectStepThree from "../components/addSubject/addSubjectStepThree.vue";
import searchSubjectName from "../pages/search_subject_result";
import searchTeacher from "../pages/search_teacher_result";
import NotFoundPage from "../pages/staticPages/NotFound.vue";
import Teachers from "../pages/Teachers/TeachersList.vue";
import TeacherProfile from "../pages/Teachers/TeacherProfile.vue";
// END:: IMPORTING ROUTER COMPONENTS

Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        component: AppContentWrapper,
        children: [
            {
                path: "/",
                component: HomePage,
            },
            {
                path: "/about-us",
                name: "AboutUsPage",
                component: AboutUsPage,
            },
            {
                path: "/privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicyPage,
            },
            {
                path: "/terms",
                name: "TermsAndConditions",
                component: TermsAndConditionsPage,
            },
            {
                path: "/reports/course=:courseId/student=:id",
                name: "reports",
                component: reportsPage,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/add-report/course=:courseId/student=:id",
                name: "AddedReports",
                component: AddReport,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/quiz/:courseType/:id",
                name: "quiz",
                component: Quiz,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/show-quiz/:courseType/:id",
                name: "allQuizes",
                component: AllQuizes,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/user-account",
                name: "UserAccount",
                component: UserAccount,
                children: [
                    {
                        path: "/user-account",
                        name: "PersonalInfoForm",
                        component: PersonalInfoForm,
                        meta: {
                            middleware: [auth],
                        },
                    },
                    {
                        path: "/career_info",
                        name: "CareerInfoForm",
                        component: CareerInfoForm,
                        meta: {
                            middleware: [auth],
                        },
                    },
                ],
            },
            {
                path: "/chats",
                name: "chats",
                children: [
                    {
                        path: "/chats",
                        name: "ChatsList",
                        components: {
                            chats_list_router_view: ChatsList,
                            chat_content_router_view: SelectChatMessage,
                        },
                        meta: {
                            middleware: [auth],
                        },
                    },
                    {
                        path: "/chat/:id",
                        name: "ChatContent",
                        components: {
                            chats_list_router_view: ChatsList,
                            chat_content_router_view: ChatContent,
                        },
                        meta: {
                            middleware: [auth],
                        },
                    },
                ],
                components: {
                    default: Chats,
                    chats_list_router_view: ChatsList,
                    chat_content_router_view: ChatContent,
                },
            },
            {
                path: "/strengthening-requests",
                name: "StrengtheningRequests",
                component: StrengtheningRequests,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/my-sons",
                name: "Sons",
                component: Sons,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/courses-categories/:id",
                name: "CoursesCategories",
                component: CoursesCategories,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/courses-categories/:son_id/:id",
                name: "SonCoursesCategories",
                component: CoursesCategories,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/son-courses/:id",
                alias: ["/courses"],
                name: "Courses",
                component: Courses,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/added-subjects",
                name: "AddedCourses",
                component: AddedCourses,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/course-videos/:id",
                name: "VideosList",
                component: VideosList,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/course-payment/:id",
                name: "CoursePayment",
                component: CoursePayment,
                children: [
                    {
                        path: "/course-payment/:id",
                        name: "CourseRegisterPayment",
                        component: CourseRegisterPayment,
                        meta: {
                            middleware: [auth],
                        },
                    },
                    {
                        path: "/payment-form",
                        name: "CoursePaymentForm",
                        component: PaymentForm,
                        meta: {
                            middleware: [auth],
                        },
                    },
                ],
            },
            {
                path: "/favorites",
                name: "Favorites",
                component: Favorites,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/course-section/:id/:type?",
                name: "CoursesSection",
                component: CoursesSection,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/strengthening-requests-teachers/:id",
                name: "StrengtheningRequestsCourses",
                component: StrengtheningRequestsCourses,
                meta: {
                    middleware: [auth],
                },
            },
            // teacher
            {
                path: "/teacher/course-details/:type/:id",
                name: "course_details_of_teacher",
                component: CourseDetails,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/teacher/my-course/:type/:id",
                name: "teacher_my_course",
                component: CourseDetails,
                meta: {
                    middleware: [auth],
                },
            },
            // student
            {
                path: "/student/course-details/:type/:id",
                name: "student_course_details",
                component: CourseDetails,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/teachers",
                name: "teachers",
                component: Teachers,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/teacher/:id",
                name: "TeacherProfile",
                component: TeacherProfile,
                meta: {
                    middleware: [auth],
                },
            },
            // parent
            {
                path: "/parent/course-details/:type/:id",
                name: "parent_course_details",
                component: CourseDetails,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/my-courses",
                name: "MyCourses",
                component: MyCourses,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/store",
                name: "TheStore",
                component: TheStore,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/shopping-cart",
                name: "ShoppingCart",
                component: ShoppingCart,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/checkout",
                name: "Checkout",
                component: Checkout,
                children: [
                    {
                        path: "/checkout",
                        name: "DeliveryInfo",
                        component: DeliveryInfo,
                        meta: {
                            middleware: [auth],
                        },
                    },
                    {
                        path: "/payment",
                        name: "PaymentForm",
                        component: PaymentForm,
                        meta: {
                            middleware: [auth],
                        },
                    },
                ],
            },
            {
                path: "/my-orders",
                name: "MyOrders",
                component: MyOrders,
                children: [
                    {
                        path: "/my-orders",
                        name: "OrdersList",
                        component: OrdersList,
                        meta: {
                            middleware: [auth],
                        },
                    },
                    {
                        path: "/order-details/:id",
                        name: "OrderDetails",
                        component: OrderDetails,
                        meta: {
                            middleware: [auth],
                        },
                    },
                ],
            },
            {
                path: "/my-balance",
                name: "TeacherBalance",
                component: TeacherBalance,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/withdraw",
                name: "Withdraw",
                component: Withdraw,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/add-subject",
                name: "AddSubject",
                component: AddSubject,
                children: [
                    {
                        path: "/add-subject",
                        name: "addSubjectStepOne",
                        component: addSubjectStepOne,
                        meta: {
                            middleware: [auth],
                        },
                    },
                    {
                        path: "/add-subject/step-two",
                        name: "addSubjectStepTwo",
                        component: addSubjectStepTwo,
                        meta: {
                            middleware: [auth],
                        },
                    },
                    {
                        path: "/add-subject/step-three",
                        name: "addSubjectStepThree",
                        component: addSubjectStepThree,
                        meta: {
                            middleware: [auth],
                        },
                    },
                ],
            },
            {
                path: "/edit-course/:id",
                name: "editCourse",
                component: editCourse,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/search-result/subject_name/:text",
                name: "search_result_subject_name",
                component: searchSubjectName,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "/search-result/teacher/:text",
                name: "search_result_teacher",
                component: searchTeacher,
                meta: {
                    middleware: [auth],
                },
            },
        ],
    },
    {
        path: "/login",
        name: "Authentication",
        component: Authentication,
        children: [
            {
                path: "/login",
                name: "LoginForm",
                component: LoginForm,
            },
            {
                path: "/reset-password-phone-number",
                name: "ResetPasswordPhoneNumberForm",
                component: ResetPasswordPhoneNumberForm,
            },
            {
                path: "/phone-verification/:verification_type",
                name: "PhoneVerification",
                component: PhoneVerification,
            },
            {
                path: "/chang-phone-number",
                name: "ChangePhoneNumberForm",
                component: ChangePhoneNumberForm,
            },
            {
                path: "/reset-password",
                name: "ResetPasswordForm",
                component: ResetPasswordForm,
            },
            {
                path: "/select-register-type",
                name: "SelectRegisterationType",
                component: SelectRegisterationType,
            },
            {
                path: "/register-first-step/:user_type",
                name: "RegisterFirstStepForm",
                component: RegisterFirstStepForm,
            },
            {
                path: "/register-second-step",
                name: "RegisterSecondStepForm",
                component: RegisterSecondStepForm,
            },
        ],
    },
    {
        path: "/:notFound(.*)",
        name: "NotFoundPage",
        component: NotFoundPage,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: "smooth",
            };
        }
    },
    routes,
});

// START:: MIDDLEWARE AND ROUTER GUARDS
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    } else if (localStorage.getItem("elmo3lm_elmosa3d_user_token")) {
        if (
            to.name == "LoginForm" ||
            to.name == "ResetPasswordPhoneNumberForm" ||
            to.name == "PhoneVerification" ||
            to.name == "ChangePhoneNumberForm" ||
            to.name == "ResetPasswordForm" ||
            to.name == "SelectRegisterationType" ||
            to.name == "RegisterFirstStepForm"
        ) {
            return next("/");
        } else {
            return next();
        }
    }
    return next();
});
// END:: MIDDLEWARE AND ROUTER GUARDS

export default router;
