var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('MainLoader'):_c('div',{staticClass:"home_wrapper fadeIn"},[(_vm.homeData)?_c('HeroSlider',{attrs:{"sliderData":_vm.homeData.sliders}}):_vm._e(),(_vm.homeData)?_c('AboutSection',{attrs:{"aboutUsData":_vm.homeData.about}}):_vm._e(),(
                _vm.getAuthenticatedUserData.token &&
                _vm.getAuthenticatedUserData.type != 'parent'
            )?_c('CoursesCategories',{attrs:{"courseCatsData":_vm.homeData?.categories}}):_vm._e(),(_vm.getAuthenticatedUserData.type == 'parent')?_c('ParentFeaturedCourses',{attrs:{"Items":_vm.homeData.my_children}}):_vm._e(),(_vm.getAuthenticatedUserData.type == 'student')?_c('StudentFeaturedTeachers',{attrs:{"Items":_vm.homeData?.teachers}}):_vm._e(),(
                _vm.getAuthenticatedUserData.type != 'parent' &&
                _vm.getAuthenticatedUserData.type != 'teacher'
            )?_c('StudentFeaturedCourses',{attrs:{"Items":_vm.homeData?.newer_subject_name}}):_vm._e(),(_vm.getAuthenticatedUserData.type == 'teacher')?_c('TeacherFeaturedCourses',{attrs:{"Items":_vm.homeData?.my_subjects}}):_vm._e(),(_vm.homeData)?_c('ContactUs',{attrs:{"contactUsData":_vm.homeData?.contacts}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }