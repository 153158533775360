export default {
    // =================== START:: STORE MUTATIONS ===================
    // START:: REMOVE FROM CART
    removeFromCart(state, payload) {
        let targetElement = state.shoppingCartItems.find(
            (element) => element == payload
        );
        let indexOfTargetElement =
            state.shoppingCartItems.indexOf(targetElement);
        state.shoppingCartItems.splice(indexOfTargetElement, 1);
    },
    // END:: REMOVE FROM CART

    // START:: STORE ITEMS
    setStoreItems(state, payload) {
        state.storeItems = payload.data;
        state.last_page = payload.meta.last_page;
    },
    // END:: STORE ITEMS

    // START:: CART ITEMS
    setCartItems(state, payload) {
        state.shoppingCartItems = payload;
    },
    setShowCash(state, payload) {
        state.show_cash = payload;
    },
    // END:: CART ITEMS
    // =================== END:: STORE MUTATIONS ===================
};
